import { EV } from 'enerx-shared';
import { toast } from "react-toastify";
import { confirmations } from "utility/confirmations";
import { Log } from "./log";
import { DbCol } from "fb-web";

export class UpdateUtils {
    constructor(private log: Log) {}

    async updateAndToast (doc: any, col: DbCol<any>, insert = false, notify = true) {
        try {
            const newId = insert ? await col.setDoc(doc.fsid, doc) : await col.save(doc);
            if (notify) toast.success(`Successfully saved ${col.title}`);
            return newId;
        }
        catch (err) {
            this.log.error(`Cannot update ${col.name}`, { err, doc });
            // so far we don't expect errors on save so any error is unexpected
            toast.error(`Unexpected error saving ${col.title}. Please try again in a moment.`)
        }
    }

    async confirmDeleteAndToast (fsid: string, col: DbCol<any>) {
        const conf = await confirmations.confirmOrCancel({ title: `Are you sure you want to delete this ${col.title}?`, text: '' });
        if (!conf.isConfirmed) return;

        try {
            await col.remove(fsid);
            toast.success(`Successfully removed ${col.title}`);
        }
        catch (err) {
            this.log.error(`Cannot remove ${col.name} ${fsid}`, err);
            const msg = (err instanceof EV.BusinessError) ? err.message : (
                err.message?.includes('Failed to get documents from server.') ? `Cannot remove ${col.name} while device is offline.` : `Unexpected error removing ${col.title}. Please try again in a moment.`
            );
            toast.error(msg);
        }
    }
}