import { EV } from 'enerx-shared';
import { useEffect, useMemo, useState } from "react";
import { IAugmentedLineItem } from "model/line.item";
import { model } from 'model';

interface IAreaWithLineItems {
    area: EV.IArea;
    lineItems: IAugmentedLineItem[];
}

export function useAreaLineItems(auditFsid: string) {
    const { activeAuditAreas, master, activeAuditExistings } = model.useAuditCtx();
    const [areaItems, setAreaItems] = useState<IAreaWithLineItems[]>([]);
    const lineItemModel = model.useLineItem(master, activeAuditExistings, auditFsid);

    useEffect(() => {
        if (!activeAuditAreas) return
        return lineItemModel.lineItemCol.subscribeAll(newLst => {
            const augmented = newLst.map(itm => ({
                ...itm,
                ftypName: master.existingFType.find(e => e.id == itm.existingFType)?.label ?? '',
                existingName: activeAuditExistings.find(e => e.fsid == itm.existingFixtureFsid)?.id ?? ''
            }));

            const items = activeAuditAreas.map(area => ({
                area,
                lineItems: augmented.filter(item => item.areaFsid === area.fsid)
            }));

            setAreaItems(items);
        });
    }, [auditFsid, activeAuditAreas]);

    const updateQuantity = async (lineItem: IAugmentedLineItem, increment: number | null, directValue?: number) => {
        const newQty = increment !== null ? Math.max(0, lineItem.qty + increment) : Math.max(0, directValue ?? 0);
            
        await lineItemModel.save({
            ...lineItem,
            qty: newQty
        }, false);
    };

    return {
        areaItems,
        incrementQty: (lineItem: IAugmentedLineItem) => updateQuantity(lineItem, 1),
        decrementQty: (lineItem: IAugmentedLineItem) => updateQuantity(lineItem, -1),
        updateQty: (lineItem: IAugmentedLineItem, value: number) => updateQuantity(lineItem, null, value),
        lineItemModel
    };
}

export type IAreaLineItemsModel = ReturnType<typeof useAreaLineItems>;