import LayoutWrapper from "@core/layouts/components/layout-wrapper";
import VerticalLayout from "layouts/VerticalLayout";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useLayout } from "../utility/hooks/useLayout";
import { useRouterTransition } from "utility/hooks/useRouterTransition";
import Error from "views/Error";
import { Audits } from "views/audit/Audits";
import { Home } from "views/Home";
import { model } from "model";
import NotAuthorized from "./NotAuthorized";
import { Login } from "./Login";
import { AuditDetails } from "./audit/AuditDetails";
import { ManageAreasPage } from "./audit/ManageAreasPage";
import { ManageAuditPhotos } from "./audit/ManageAuditPhotos";
import { ManageAuditTally } from "./audit/ManageAuditTally";

const InAuditCtx = ({ children }: { children: JSX.Element }) => {
    const auditCtx = model.useAudit();
    return <model.AuditCtx.Provider value={auditCtx}>
        { children }
    </model.AuditCtx.Provider>
}

const Router = () => {
    const { layout, lastLayout, setLayout, setLastLayout } = useLayout();
    const { transition, setTransition } = useRouterTransition();

    const MainRoute = ({ path, children }: { path: string, children: JSX.Element }) => {
        const { status } = model.useAppCtx().auth;

        if (status == 'loading') return <Spinner />;
        if (status == 'invalid') return <Redirect to="/not-authorized" />;
        if (status == 'anon') return <Redirect to="/login" />;

        return (
            <Route exact path={path}>
                <VerticalLayout {...{ layout, lastLayout, setLayout, setLastLayout, transition, setTransition }}>
                    <LayoutWrapper {...{ layout, transition, setTransition }}>
                        <InAuditCtx children={children}  />
                    </LayoutWrapper>
                </VerticalLayout>
            </Route>
        );
    }

    const NoSideBarAndHeader = ({ path, children }: { path: string, children: JSX.Element }) => {
        return (
            <Route exact path={path}>
                <VerticalLayout hideSideBar={true} hideHeader={true}>
                    <LayoutWrapper {...{ transition }} hideHeader={true}>
                        {children}
                    </LayoutWrapper>
                </VerticalLayout>
            </Route>
        );
    }

    return (
        <BrowserRouter>
            <Switch>
                <MainRoute path="/home">
                    <Home />
                </MainRoute>
                <MainRoute path="/audit/:id/areas">
                    <ManageAreasPage />
                </MainRoute>
                <MainRoute path="/audit/:id/photos">
                    <ManageAuditPhotos />
                </MainRoute>
                <MainRoute path="/audit/:id/tally">
                    <ManageAuditTally />
                </MainRoute>
                <MainRoute path="/audits">
                    <Audits />
                </MainRoute>
                <MainRoute path="/audit/:id">
                    <AuditDetails />
                </MainRoute>
                

                <NoSideBarAndHeader path="/not-authorized">
                    <NotAuthorized />
                </NoSideBarAndHeader>
                <NoSideBarAndHeader path="/login">
                    <Login />
                </NoSideBarAndHeader>

                <Route exact path="/">
                    <Redirect to="/home" />
                </Route>

                <Route path="/*">
                    <Error />;
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default Router;
