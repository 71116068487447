import { IOnsiteAuditPhotoModel } from "model/photos/hooks";
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource, CameraPermissionType } from '@capacitor/camera';
import { useEffect, useRef, useState, ChangeEvent } from 'react';
import { Button, Label } from 'reactstrap';
import { PhotoSwiper } from './PhotoSwiper';
import { RenderPhoto } from './RenderPhoto';
import { Trash2, Camera as CameraIcon, Upload } from 'react-feather';

export const AttachedPhotos = ({ model, mode, size }: { model: IOnsiteAuditPhotoModel, mode: 'photos' | 'camera', size?: 'lg' | 'std' }) => {
    const [hasCameraPermission, setHasCameraPermission] = useState(false);
    const [hasGalleryPermission, setHasGalleryPermission] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isTakingPhoto, setIsTakingPhoto] = useState(false);
    const [pendingUploads, setPendingUploads] = useState(0);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const checkInitialPermissions = async () => {
        const permissions = await Camera.checkPermissions();
        setHasCameraPermission(permissions.camera === 'granted');
        setHasGalleryPermission(permissions.photos === 'granted');
    };

    useEffect(() => {
        checkInitialPermissions();
    }, []);

    useEffect(() => {
        if (mode === 'camera' && !Capacitor.isNativePlatform() && inputRef.current) {
            inputRef.current.click();
        }
    }, [mode]);


    const requestCameraPermission = async () => {
        try {
            const result = await Camera.requestPermissions({
                permissions: ['camera']
            });
            setHasCameraPermission(result.camera === 'granted');
            return result.camera === 'granted';
        } catch (error) {
            console.error('Error requesting camera permission:', error);
            return false;
        }
    };

    const requestGalleryPermission = async () => {
        try {
            const result = await Camera.requestPermissions({
                permissions: ['photos']
            });
            setHasGalleryPermission(result.photos === 'granted');
            return result.photos === 'granted';
        } catch (error) {
            console.error('Error requesting gallery permission:', error);
            return false;
        }
    };

    const takePhotoNative = async () => {
        if (!hasCameraPermission) {
            const granted = await requestCameraPermission();
            if (!granted) {
                alert('Camera permission is required to take photos');
                return;
            }
        }

        try {
            setIsTakingPhoto(true);
            const photo = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                quality: 90,
            });

            if (photo.webPath) {
                const response = await fetch(photo.webPath);
                const blob = await response.blob();
                const file = new File([blob], `photo_${Date.now()}.jpg`, { type: blob.type });
                model.add(file);
            }

            takePhotoNative();
        } catch (error) {
            console.error('Error taking photo:', error);
            setIsTakingPhoto(false);
        }
    };

    const takePhotoWeb = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];
        if (file) {
            model.add(file);
        }
    };

    const startContinuousPhotos = () => {
        if (Capacitor.isNativePlatform()) {
            takePhotoNative();
        }
    };

    const pickFromGallery = async () => {
        if (!hasGalleryPermission) {
            const granted = await requestGalleryPermission();
            if (!granted) {
                alert('Gallery permission is required to select photos');
                return;
            }
        }

        try {
            setIsProcessing(true);
            const photos = await Camera.pickImages({
                quality: 90,
            });

            for (const photo of photos.photos) {
                setPendingUploads(count => count + 1);
                const response = await fetch(photo.webPath);
                const blob = await response.blob();
                const file = new File([blob], `photo_${Date.now()}_${Math.random().toString(36).slice(2)}.jpg`, { type: blob.type });
                await model.add(file);
                setPendingUploads(count => count - 1);
            }
        } catch (error) {
            console.error('Error picking photos:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <>
            {(isProcessing || isTakingPhoto || pendingUploads > 0) ? <div>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Processing...
            </div> : <>
            <div className='d-flex justify-content-center'>
                <div className='d-flex gap-2'>
                    {model.list.length > 0 && (
                        <Button style={{ marginRight: '10px' }} color='danger' onClick={model.confirmAndRemoveAll}>
                            <Trash2 />
                        </Button>
                    )}
                    {Capacitor.isNativePlatform() && (
                        <>
                            <Button 
                                color='primary' 
                                onClick={startContinuousPhotos} 
                                className="me-2"
                            >
                                <CameraIcon size={32} />
                                <div>Take Photos</div>
                            </Button>
                            <Button 
                                color='primary' 
                                onClick={pickFromGallery}
                                outline
                            >
                                <Upload size={32} />
                                <div>Upload from Gallery</div>
                            </Button>
                        </>
                    )}
                </div>

                {!Capacitor.isNativePlatform() && (
                    <div>
                        <Label htmlFor="capture_camera" className='w-100'>
                            <span className='btn btn-primary d-block w-100 fs-4 ps-1 pe-1'>
                                <CameraIcon className='me-1' />
                                Take Photo
                            </span>
                        </Label>
                        <input
                            className='d-none'
                            type="file"
                            ref={inputRef}
                            id="capture_camera"
                            name="capture_camera"
                            accept="image/*"
                            onChange={takePhotoWeb}
                            capture="environment"
                        />
                    </div>
                )}
            </div>

            <div className='mt-1'>
                {model.list.length > 0 ? (
                    <PhotoSwiper
                        size={size}
                        slides={model.list.map(photo => (
                            <RenderPhoto
                                key={photo.record.createdAt}
                                photo={photo}
                                update={dto => model.update(photo.record.fsid, dto)}
                                remove={() => model.remove(photo.record)}
                            />
                        ))}
                    />
                ) : (
                    <div style={{ height: '372px' }}></div>
                )}
            </div>
        </>}
        </>
    );
};