import { model } from "model";
import { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { AddEditAuditForm } from "./AddEditAuditForm";
import { Link } from "react-router-dom";
import { EV } from 'enerx-shared';

export const Audits = () => {
    const audit = model.useAuditCtx();
    const [showAdd, setShowAdd] = useState(false);
    return (
        <>
            <Row>
                <Col xs={6}>
                    <h2>Browse All Audits</h2>
                </Col>
                <Col cs={6} className="text-end">
                    <Button color="primary" onClick={() => setShowAdd(true)}>New Audit</Button>
                </Col>
            </Row>

            <Row className="mt-2 match-height">
            {
                audit.allAudits.map(a => (
                    <Col xs={12} sm={6} lg={4} key={a.fsid}>
                        <Card className="border-success audit-card">
                            <CardHeader>
                                <CardTitle tag='h4' className="text-primary">
                                    {a.customer} - {a.projectName}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <p>{a.address.street} {a.address.street2 ?? ''}</p>
                                <p>{EV.utils.fmt.cityStateZip(a.address)}</p>
                                <Row>
                                    <Col>Audit Date: <span className="text-nowrap">{EV.utils.fmt.dateTzFormat(a.auditDate, 'UTC')}</span></Col>
                                    <Col className="text-end">{a.auditor?.first} {a.auditor?.last}</Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <div className="p-1">
                                    <Link to={`/audit/${a.fsid}`}>
                                        <Button color="info" block outline>Visit</Button>
                                    </Link>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                ))
            }
            </Row>
            {
                showAdd && <AddEditAuditForm toggle={() => setShowAdd(false)} />
            }
      </>
    );
};