import { EV } from "enerx-shared"
import { model } from "model";
import { useState } from "react";
import { Filter, HelpCircle, List, MoreVertical } from "react-feather";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from "reactstrap";
import { LineItems } from "views/item/LineItems";
import { AddEditAuditForm } from "./AddEditAuditForm";
import { AreaSelector } from "./AreaSelector";
import { AuditDefaultsForm } from "./AuditDefaultsForm";
import { loadPhotoStatuses } from "model/photos/hooks";

export const AuditDetails = () => {
    const { activeAuditLoaded, activeAudit, activeAuditAuditor, removeAudit, reorder, emailAudit, emailPhotos, completeAudit } = model.useAuditCtx();
    const [modals, setModals] = useState<'none' | 'edit' | 'areas' | 'defaults'>('none');
    const [removed, setRemoved] = useState(false);
    const [selectedArea, setSelectedArea] = useState<EV.IArea>();
    const history = useHistory();

    if (removed) return <Redirect to="/home" />;
    if (!activeAuditLoaded) return <Spinner />;

    return (
        <>
            <Row className="mb-1">
                <Col xs={9} className="mt-1 text-primary">
                    <h2>{activeAudit.opportunityId ? `${activeAudit.opportunityId} - ` : ''}{activeAudit.customer} - {activeAudit.projectName}</h2>
                    <div className="mt-1">
                        <div className="d-inline-block me-4">
                            <h4>{activeAudit.address.street} {activeAudit.address.street2}</h4>
                            <h4>{EV.utils.fmt.cityStateZip(activeAudit.address)}</h4>
                        </div>
                        <div className="d-inline-block me-4">
                            <h4>{EV.utils.fmt.dateTzFormat(activeAudit.auditDate, 'UTC')}</h4>
                            <h4>{activeAuditAuditor?.first} {activeAuditAuditor?.last}</h4>
                        </div>
                    </div>
                </Col>
                <Col xs={3} className="text-end mt-1">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color='primary' outline>
                            <MoreVertical size={24} />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => history.push(`/audit/${activeAudit.fsid}/areas`)}>Manage Areas</DropdownItem>
                            <DropdownItem onClick={() => setModals('edit')}>Edit Audit</DropdownItem>
                            <DropdownItem onClick={() => setModals('defaults')}>Set Facility Defaults</DropdownItem>
                            <DropdownItem onClick={reorder}>Reorder Line Items</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={emailAudit}>Send Audit to Email</DropdownItem>
                            <DropdownItem onClick={emailPhotos}>Export Photos</DropdownItem>
                            <DropdownItem onClick={() => history.push(`/audit/${activeAudit.fsid}/photos`)}>Manage Photos</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={completeAudit}>Complete Audit</DropdownItem>
                            <DropdownItem onClick={() => removeAudit(activeAudit.fsid).then(() => setRemoved(true))}>Remove Audit</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </Col>
            </Row>

            <Row className="mt-2 mb-2">
                <Col xs={8}>
                    <h5>
                    {
                        selectedArea ? <>Listed line items for <strong>{selectedArea.name}</strong> area</> : `Listed all line items`
                    }
                    </h5>
                    <small className="text-info"><HelpCircle size={18} /> Use Areas button to list items for particular area</small>
                </Col>
                <Col xs={4} className="text-end">
                <div className="d-flex flex-column align-items-end">
                    <Button 
                        color="primary"
                        className="mb-2"
                        outline
                        onClick={() => history.push(`/audit/${activeAudit.fsid}/tally`)}
                    >
                        <List size={24} />
                    </Button>
                    <Button 
                        color="primary" 
                        outline={!selectedArea} 
                        onClick={() => setModals('areas')}
                    >
                        <Filter size={24} />
                    </Button>
                </div>
                </Col>
            </Row>

            <LineItems audit={activeAudit} selectedArea={selectedArea} />

            {
                modals == 'edit' && <AddEditAuditForm original={activeAudit} toggle={() => setModals('none')} />
            }            
            {
                modals == 'areas' && <AreaSelector toggle={() => setModals('none')} selectedAreas={selectedArea ? [selectedArea.fsid] : []} onSelect={area => {
                                            setSelectedArea(area);
                                            setModals('none');
                                        }} 
                                     />
            }
            {
                modals == 'defaults' && <AuditDefaultsForm original={activeAudit} toggle={() => setModals('none')} />
            } 
        </>
    );
}