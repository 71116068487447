import { EV } from "enerx-shared";
import { model } from "model";
import { useState } from "react";
import { ChevronLeft, Edit2, Filter } from "react-feather";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col, Spinner } from "reactstrap";
import { AddEditLineItem } from "views/item/AddEditLineItem";
import { useAreaLineItems } from "views/item/hooks/useAreaLineItems";
import { AddEditArea } from "./AddEditArea";
import { AreaSelector } from "./AreaSelector";


export const AuditTally = () => {
    const { id } = useParams<{ id: string }>();
    const { activeAudit, activeAuditLoaded } = model.useAuditCtx();
    const { areaItems, incrementQty, decrementQty, updateQty, lineItemModel } = useAreaLineItems(id);
    const [glowStates, setGlowStates] = useState<Record<string, { isGlowing: boolean; isIncrease: boolean }>>({});
    const [modal, setModal] = useState<'area' | 'lineItem' | 'editArea' | 'none' | 'areaFilter'>('none');
    const [selectedArea, setSelectedArea] = useState<EV.IArea | null>(null);
    const [filteredAreas, setFilteredAreas] = useState<string[]>([]);

    if (!activeAuditLoaded) return <Spinner />;
    const handleAreaFilter = (areaId: string | null) => {
        if (areaId) {
        setFilteredAreas(prev => {
            const isAreaFiltered = prev.includes(areaId);
            if (isAreaFiltered) {
                return prev.filter(id => id !== areaId);
            } else {
                    return [...prev, areaId];
                }
            });
        } else {
            setFilteredAreas([]);
        }
    };

    const displayedAreaItems = filteredAreas.length > 0 ? areaItems.filter(({ area }) => filteredAreas.includes(area.fsid)) : areaItems;

    const handleQuantityChange = (item: any, isIncrease: boolean) => {
        if (isIncrease) {
            incrementQty(item);
        } else {
            decrementQty(item);
        }

        setGlowStates(prev => ({
            ...prev,
            [item.fsid]: { isGlowing: true, isIncrease }
        }));

        setTimeout(() => {
            setGlowStates(prev => ({
                ...prev,
                [item.fsid]: { isGlowing: false, isIncrease }
            }));
        }, 500);
    };

    const handleDirectQuantityChange = (item: any, value: string) => {
        const newQty = parseInt(value) || 0;
        if (newQty >= 0) {
            updateQty(item, newQty);
            
            setGlowStates(prev => ({
                ...prev,
                [item.fsid]: { isGlowing: true, isIncrease: newQty > item.qty }
            }));

            setTimeout(() => {
                setGlowStates(prev => ({
                    ...prev,
                    [item.fsid]: { isGlowing: false, isIncrease: false }
                }));
            }, 500);
        }
    };

    return (
        <>
        {
            modal == 'lineItem' && <AddEditLineItem 
                model={lineItemModel} 
                onComplete={(status?: 'cancel'|'ok') => {  
                    setModal('none');
                    setSelectedArea(null);
                 }
                } 
                selectedArea={selectedArea}
                initialMode="props" 
            />
        }

        {
            modal == 'area' && <AddEditArea origin={null} toggle={() => setModal('none')}></AddEditArea>
        }

        {
            modal == 'editArea' && <AddEditArea 
                origin={selectedArea} 
                toggle={() => {
                    setSelectedArea(null);
                    setModal('none');
                }}
            />
        }

            {modal === 'areaFilter' && (
                <AreaSelector 
                    toggle={() => setModal('none')} 
                    selectedAreas={filteredAreas}
                    onSelect={area => handleAreaFilter(area ? area.fsid : null)}
                />
            )}
            <Row className="mb-3">
                <Col>
                
                <Link 
                        to={`/audit/${id}`}
                        className="text-primary d-flex align-items-center" 
                        style={{ textDecoration: 'none', width: 'fit-content' }}
                    >
                        <ChevronLeft size={16} className="me-1" />
                        Back to Audit
                    </Link>
                </Col>
            </Row>

            <Row className="mb-2">
                <div className="d-flex align-items-center justify-content-between">
                    <h2>{activeAudit.opportunityId ? `${activeAudit.opportunityId} - ` : ''}{activeAudit.customer} - {activeAudit.projectName}</h2>
                    <Button
                        color="primary"
                        outline = {filteredAreas.length == 0}
                        size="sm"
                        onClick={() => setModal('areaFilter')}
                    >
                        <Filter size={24} />
                    </Button>
                </div>
                <hr />
            </Row>

            <Row>
                <Col xs={12}>
                    {displayedAreaItems.map(({ area, lineItems }) => (
                        <div key={area.fsid} className="mb-4">
                            <div className="d-flex align-items-center gap-2">
                                <h4 className="fw-bolder mb-0">{area.name}</h4>
                                <Button 
                                    color="flat-primary" 
                                    size="sm" 
                                    className="p-0"
                                    onClick={() => {
                                        setSelectedArea(area);
                                        setModal('editArea');
                                    }}
                                >
                                    <Edit2 size={16} />
                                </Button>
                            </div>
                            <div className="d-flex flex-column">
                                {lineItems.map(item => (
                                    <div key={item.fsid} className="d-flex justify-content-between align-items-center border-bottom py-2">
                                        <div className="d-flex flex-column">
                                            <span className="text-primary">{item.name}</span>
                                            <small className="text-muted">{item.existingName}</small>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <Button
                                                size="sm"
                                                outline
                                                onClick={() => handleQuantityChange(item, false)}
                                                disabled={item.qty <= 0}
                                                style={{
                                                    borderColor: 'black',
                                                    color: 'black',
                                                    width: '38px',
                                                    height: '38px',
                                                    padding: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                -
                                            </Button>
                                            <input
                                                type="number"
                                                value={item.qty}
                                                onChange={(e) => handleDirectQuantityChange(item, e.target.value)}
                                                style={{
                                                    width: '38px',
                                                    height: '38px',
                                                    textAlign: 'center',
                                                    border: '1px solid black',
                                                    borderRadius: '0.25rem',
                                                    fontSize: '16px',
                                                    transition: 'box-shadow 0.3s ease',
                                                    boxShadow: glowStates[item.fsid]?.isGlowing ? glowStates[item.fsid]?.isIncrease ? '0 0 16px 6px rgba(40, 167, 69, 1), 0 0 20px 8px rgba(40, 167, 69, 0.8)' : '0 0 16px 6px rgba(220, 53, 69, 1), 0 0 20px 8px rgba(220, 53, 69, 0.8)' : 'none'
                                                }}
                                                min="0"
                                            />
                                            <Button
                                                size="sm"
                                                outline
                                                onClick={() => handleQuantityChange(item, true)}
                                                style={{
                                                    borderColor: 'black',
                                                    color: 'black',
                                                    width: '38px',
                                                    height: '38px',
                                                    padding: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Button color="primary" onClick={ () => { setSelectedArea(area); setModal('lineItem') } } className="mt-3 w-100">
                                Add Line Item
                            </Button>
                        </div>
                    ))}
                    <Button color="primary" onClick={() => setModal('area')} outline className="w-100">
                        Add New Area
                    </Button>
                </Col>
            </Row>
        </>
    );
};